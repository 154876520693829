._3zRJQ, ._3KcaM{
    display: none;
}
.calendar > text {
    display: block !important;
}

._nI1Xw > div {
    font-weight: bold !important;
}

._nI1Xw:hover {
    overflow: visible; 
    white-space: normal;
    height:auto;
}